import { trackEvent } from '@centered/analytics'
import CenteredLogoWhite from '@centered/components/dist/icons/CenteredLogoWhite'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'
import TryCenteredButtonTeam from '../teams-landing-page/TryCenteredButton'
import V2TryCenteredButton from '../v2-landing-page/TryCenteredButton'
import V4CTAButton from '../landing-page-v4/TryCenteredButton'
import V5CTAButton from '../landing-page-v5/TryCenteredButton'
import V6CTAButton from '../landing-page-v6/TryCenteredButton'
import V7CTAButton from '../landing-page-v7/TryCenteredButton'
import V8CTAButton from '../landing-page-v8/TryCenteredButton'
import Writers1CTAButton from '../writers/TryCenteredButton'
import DDCTAButton from '../dd/TryCenteredButton'
import ActiveLink from './ActiveLink'
import CenteredTextLogo from './CenteredTextLogo'
import { HEADER_ADJUSTING_STYLES } from './PageHeader'
import TryCenteredButton from './TryCenteredButton'
import Wrapper from './Wrapper'
 
interface HeaderProps {
  alwaysShowSignupButton?: boolean
  noImageBackground?: boolean
  redirectURL?: string
  onlyLogo?: boolean
  v2?: boolean
  v4?: boolean
  v5?: boolean
  v6?: boolean
  v7?: boolean
  v8?: boolean
  writers1?: boolean
  dd?: boolean
  disableStickyHeader?: boolean
  teamSignup?: boolean
  adhd?: boolean
  remote?: boolean
}

export default function Header({
  noImageBackground = false,
  onlyLogo = false,
  v2 = false,
  v4 = false,
  v5 = false,
  v6 = false,
  v7 = false,
  v8 = false,
  disableStickyHeader = false,
  teamSignup,
  adhd,
  remote,
  writers1,
  dd,
}: HeaderProps): JSX.Element {
  const { y } = useWindowScroll()
  const [hasScrolled, setHasScrolled] = useState(false)
  const shouldShowHeader = hasScrolled

  useEffect(() => {
    if (disableStickyHeader) {
      return
    }

    const heroCTASection = document.querySelector('.hero .tryCenteredButton')

    if (heroCTASection) {
      setHasScrolled(
        y > heroCTASection.getBoundingClientRect().y + window.pageYOffset + 50,
      )
    } else {
      setHasScrolled(y > 200)
    }
  }, [y, disableStickyHeader])

  const router = useRouter()

  return (
    <header
      className={`w-full py-5 flex flex-col lg:flex-row lg:items-center lg:justify-between z-50 text-white ${
        noImageBackground || shouldShowHeader ? 'bg-header' : ''
      } ${
        hasScrolled
          ? 'fixed header-animation md:top-0 md:bottom-auto '
          : 'sticky'
      }`}
      style={{
        filter: hasScrolled
          ? 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2))'
          : 'none',
        height: Math.abs(HEADER_ADJUSTING_STYLES.marginTop),
      }}
    >
      <style jsx global>{`
        .intercom-lightweight-app {
          display: none !important;
        }
      `}</style>
      <style jsx>{`
        .bg-header {
          background-color: #000000;
        }

        .header-animation {
          animation: slide-in 1s ease-in-out;
          animation-fill-mode: forwards;
        }

        @keyframes slide-in {
          from {
            bottom: -100%;
          }
          to {
            bottom: 0;
          }
        }
      `}</style>
      <Wrapper
        disablePadding={true}
        className="relative py-0 px-4 h-full flex justify-between gap-2 lg:flex-row lg:justify-between"
        style={{
          maxWidth: '85rem',
        }}
      >
        <div
          className={`md:flex items-center ${hasScrolled ? 'hidden ' : 'flex'}`}
        >
          <Link
            aria-label="Sukha logo"
            href={teamSignup ? '/teams' : '/'}
            className="inline-flex items-center"
          >
            <CenteredLogoWhite className="md:mr-4" />
            <CenteredTextLogo className="hidden md:block" />
          </Link>
        </div>

        {!onlyLogo && (
          <nav
            className={`font-semibold flex flex-row gap-4 items-center w-full ${
              hasScrolled ? 'justify-center md:justify-end' : 'justify-end'
            }`}
          >
            {router.asPath.startsWith('/auth/login') ||
            router.asPath.startsWith('/auth/signup') ||
            disableStickyHeader ? null : (
              <div className={`${hasScrolled ? 'hidden' : 'hidden md:block'}`}>
                <NavLink
                  href="/auth/login"
                  text="Login"
                  onClick={() => {
                    trackEvent('HomePageCTAClicked', {
                      ctaSource: 'login',
                    })
                  }}
                />
              </div>
            )}

            <div
              className={`${
                hasScrolled || disableStickyHeader ? '' : 'hidden'
              }`}
            >
              {/* Adding V4 CTA button here and commenting out original code below - MC */}
              {v5 ? (
                <V5CTAButton
                  source="v5CTAHeader"
                  noGoogleAuthSource="v5CTAHeaderNonGoogle"
                />
              ) : v4 ? (
                <V4CTAButton
                  source="v4CTAHeader"
                  noGoogleAuthSource="v4CTAHeaderNonGoogle"
                />
              ) : v6 ? (
                  <V6CTAButton
                    source="v6CTAHeader"
                    noGoogleAuthSource="v6CTAHeaderNonGoogle"
                  />
              ) : v7 ? (
                  <V7CTAButton
                    source="v7CTAHeader"
                    noGoogleAuthSource="v7CTAHeaderNonGoogle"
                  />
              ) : v8 ? (
                  <V8CTAButton
                    source="v8CTAHeader"
                    noGoogleAuthSource="v8CTAHeaderNonGoogle"
                  />
              ) : dd ? (
                  <DDCTAButton
                    source="ddCTAHeader"
                    noGoogleAuthSource="ddCTAHeaderNonGoogle"
                  />
              ) : writers1 ? (
                  <Writers1CTAButton
                    source="writers1CTAHeader"
                    noGoogleAuthSource="writers1CTAHeaderNonGoogle"
                  />
              ) : remote ? (
                  <V2TryCenteredButton
                    source="remoteHeader"
                    noGoogleAuthSource="remoteHeaderNonGoogle"
                  />
              ) : v2 || adhd ? (
                <V2TryCenteredButton
                  source={v2 ? 'v2CTAHeader' : 'adhdHeader'}
                  noGoogleAuthSource={
                    v2 ? 'v2CTAHeaderNonGoogle' : 'adhdHeaderNonGoogle'
                  }
                />
              ) : teamSignup ? (
                <TryCenteredButtonTeam source="teamsCTAHeader" />
              ) : (
                <TryCenteredButton />
              )}
            </div>
            {/* <div
              className={`${
                hasScrolled || disableStickyHeader ? '' : 'hidden'
              }`}
            >
              {v2 || adhdSignup ? (
                <V2TryCenteredButton
                  source={v2 ? 'v2CTAheaderGoogle' : 'adhdCTAheaderGoogle'}
                  noGoogleAuthSource={
                    v2 ? 'v2CTAheadernonGoogle' : 'adhdCTAheadernonGoogle'
                  }
                />
              ) : teamSignup ? (
                <TryCenteredButtonTeam source="teamsCTAheader" />
              ) : (
                <TryCenteredButton />
              )}
            </div> */}
          </nav>
        )}
      </Wrapper>
    </header>
  )
}

interface NavLinkProps {
  href: string
  text: string
  onClick?: () => void
}

function NavLink({ href, text, onClick }: NavLinkProps): JSX.Element {
  return (
    <ActiveLink
      href={href}
      text={text}
      onClick={onClick}
      className="block lg:border-b-2 border-transparent text-opacity-50 lg:text-opacity-100 py-2 hover:text-opacity-80 transition-colors "
      activeClassName="bg-white bg-opacity-10 rounded-l-md lg:rounded-none lg:border-v2-accent lg:bg-transparent"
    />
  )
}
