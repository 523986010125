import React from 'react'
import Wrapper from '../shared/Wrapper'


export default function OrgUsers(): JSX.Element {
  return (
    <Wrapper>
      <div className="mt-20 mb-15">
      <div className="text-white">
        <header className="text-center mb-20">
          <h2 className="font-bold text-2xl md:text-3xl mb-4">
            ...with the best tools to get your work done:
          </h2>
          <p className="text-white text-opacity-80 text-lg md:text-2xl">
            Sukha includes techniques from the respected productivity research of Csikszentmihalyi, Newport, Clear, Eyal & others.
          </p>
        </header>

       
      </div>
      </div>
    </Wrapper>
  )
}
