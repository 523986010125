import Wrapper from '../shared/Wrapper'
import { PriceCard } from '../v2-landing-page/Pricing'

export default function Pricing(): JSX.Element {
  return (
    <Wrapper className="flex flex-col md:flex-row justify-between items-center gap-8 py-20">
      <div className="flex-1">
        <h1 className="text-2xl lg:text-4xl lg:leading-snug font-bold mb-6">
          And we made it affordable.
        </h1>

        <p className="text-lg text-white text-opacity-80">
        14-day Free Trial. 100% Happiness Guarantee.
          <br /><br />
          If you don't love Sukha, we'll refund you. No questions asked.
        </p>
      </div>

      {/*<PriceCard plan="free" />*/}
      <PriceCard plan="pro" />
    </Wrapper>
  )
}
