import { trackEvent } from '@centered/analytics'
import { useRef } from 'react'
import { HEADER_ADJUSTING_STYLES } from '../shared/PageHeader'
import Wrapper from '../shared/Wrapper'
import TryCenteredButton from './TryCenteredButton'

export default function HeroSection(): JSX.Element {
  return (
    <div
      className="bg-black"
      style={{
        ...HEADER_ADJUSTING_STYLES,
      }}
    >
      <Wrapper className="bg-black py-10">
        <div className="hero flex flex-col md:flex-row md:items-center gap-10">
          <div className="md:w-1/2 flex-shrink-0">
            <h1 className="text-3xl font-bold mb-8 leading-snug">
            Sukha's a website you run in the background while you work...
            </h1>
            <p className="text-2xl md:mr-8 leading-normal mb-6">
            <br/>
            </p>

          </div>

          <AppPreview />
        </div>
      </Wrapper>
    </div>
  )
}

export function AppPreview(): JSX.Element {
  const ref = useRef<HTMLVideoElement>(null)

  return (
    <div
      onClick={() => {
        trackEvent('Sampled home page audio', {
          type: 'hero',
        })
      }}
      className="w-full md:w-1/2 relative"
    >
      <div
        onClick={() => {
          if (ref.current) {
            ref.current.muted = false
          }
        }}
        className="cursor-pointer right-0 -top-14 px-4 py-4 rounded-md text-center opacity-80"
      >
        Click for sound
      </div>

      <video
        width="100%"
        style={{
          maxWidth: 700,
        }}
        ref={ref}
        muted
        loop
        autoPlay
        playsInline
        controls
      >
        <source src={`/assets/how-centered-works.mp4`} type="video/mp4" />
      </video>
    </div>
  )
}
