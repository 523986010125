import React from 'react'
import Wrapper from '../shared/Wrapper'
import {
  AirbnbLogo,
  AmazonLogo,
  AppleLogo,
  AtlassianLogo,
  EpicGamesLogo,
  GoogleLogo,
  MetaLogo,
  MicrosoftLogo,
  SalesforceLogo,
  ShopifyLogo,
  WorkdayLogo,
} from './CompanyLogos'

const LOGOS = [
  MetaLogo,
  GoogleLogo,
  MicrosoftLogo,
  AmazonLogo,
  AtlassianLogo,
  AirbnbLogo,
  ShopifyLogo,
  AppleLogo,
  EpicGamesLogo,
  WorkdayLogo,
  SalesforceLogo,
]

export default function OrgUsers(): JSX.Element {
  return (
    <Wrapper>
      <div className="mt-10 mb-10">
      <div className="text-white">
        <header className="text-center mb-20">
          <h2 className="font-bold text-2xl md:text-4xl mb-4">
            Who else uses Sukha?
          </h2>
          <p className="text-white text-opacity-80 text-lg md:text-2xl">
            Members from these organizations and more get their work done with
            Sukha.
          </p>
        </header>

        <div className="w-full max-w-3xl mx-auto">
          <div className="flex flex-wrap justify-center gap-6 gap-y-12 md:gap-y-10 items-center md:items-start">
            {LOGOS.map((Logo, i) => (
              <React.Fragment key={`first-row-${i}`}>
                <Logo />
                {Logo === AtlassianLogo && <br className="hidden md:block" />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      </div>
    </Wrapper>
  )
}
